var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',{staticStyle:{"margin-top":"10px"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"label":"ID","prop":"id"}}),_c('el-table-column',{attrs:{"label":"企业名称","prop":"enterpriseName"}}),_c('el-table-column',{attrs:{"label":"广告名称","prop":"name"}}),_c('el-table-column',{attrs:{"label":"banner图"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p-image',{staticStyle:{"width":"120px","height":"120px"},attrs:{"src":row.banner,"auto":""}})]}}])}),_c('el-table-column',{attrs:{"label":"状态","prop":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":row.status == 0 ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(row.status==0?'待审核':'审核不通过')+" ")]),(row.remark)?_c('p',[_vm._v("审核不通过原因:"+_vm._s(row.remark))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticClass:"skyblue",attrs:{"type":"text","icon":"el-icon-edit","size":"mini"},on:{"click":function($event){return _vm.bannerCheck(1,row)}}},[_vm._v("审核通过")]),_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text","icon":"el-icon-edit","size":"mini"},on:{"click":function($event){return _vm.bannerCheck(2,row)}}},[_vm._v("审核不通过")])]}}])})],1)],1),_c('el-pagination',{attrs:{"current-page":_vm.search.page,"background":"","page-sizes":[3, 5, 10, 15],"page-size":_vm.search.pageSize,"layout":"total, prev, pager, next,jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}}),_c('el-dialog',{attrs:{"title":"提示","visible":_vm.dialogShow,"width":"30%"},on:{"update:visible":function($event){_vm.dialogShow=$event}}},[_c('el-form',{ref:"form",staticClass:"demo-ruleForm",attrs:{"model":_vm.form,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"审核失败原因","prop":"remark","rules":[
    { required: true, message: '审核失败原因不为空'}
  ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", _vm._n($$v))},expression:"form.remark"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogShow = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.subimt}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }